import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { User } from './../models/user';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { NotesService } from './notes.service';
import * as abcJS from './../../../../src/assets/js/smtp.js';
import { ValueAccessor } from '@ionic/angular/directives/control-value-accessors/value-accessor';
declare let Email: any;


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userCollection: AngularFirestoreCollection<User>;
  private users: Observable<User[]>;

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore
  ) {

    //Firebase DB Construct call
    this.userCollection = db.collection<User>('users');
    this.users = this.userCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      })
    )

  }

  registerUser(value) {
    return new Promise<any>((resolve, reject) => {
      // handle the user creation for authentication
      this.afAuth.createUserWithEmailAndPassword(value.email, value.password).then(
        res => {          
          let user: User = {
            uid: res.user.uid,
            email: value.email,
            firstname: value.fname,
            lastname: value.lname,
            displayname: value.fname + " " + value.lname,
            temppassword: value.password,
            phonenumber: value.phonenumber,
            rid: value.rid,
            apt: value.apt,
            tenanttype: value.tenanttype,
            tower: value.tower,
            temppasschanged: false,
          };
          this.userCollection.doc(res.user.uid).set(user);
          this.sendEmail(value);
          resolve(res);
        }, err => {
          reject(err);
        }
      )
    })

  }

  didUserChangedTempPass() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.currentUser.then(
        res => {  resolve(res) })
    });
  }
 
  loginUser(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => resolve(res),
          err => reject(err));
    })
  }

  logoutUser() {
    return new Promise<void>((resolve, reject) => {
      if (this.afAuth.currentUser) {
        this.afAuth.signOut()
          .then(() => {
            console.log("LOG Out");
            resolve();
          }).catch((error) => {
            reject();
          });
      }
    })
  }

  getCurrentUser() {
    if (this.afAuth.currentUser) {
      return this.afAuth.currentUser;
    } else {
      return null;
    }
  }

  userDetails() {
    return this.afAuth.user
  }

  changepassword(value) {
    return new Promise<any>((resolve, reject) => {
    
      this.afAuth.currentUser.then(
        res => { 
          this.userCollection.doc(res.uid).update({ "temppasschanged": true })
          .then(function () { console.log("user successfully changed password"); });

          res.updatePassword(value.password)
            .then(res => resolve(res),
              err => reject(err));          
        });     
    })

  }

  sendEmailVerification() {
    return new Promise<any>((resolve, reject) => {
      this.getCurrentUser()
        .then(function (user) {
          user.sendEmailVerification()
            .then(
              res => resolve(res),
              err => reject(err))
        }).catch(function (err) {
          err => reject(err)
        });
    })
  }

  sendEmail(data) {

    let email = {
      Host: "smtp.elasticemail.com",
      Username: "admin@nexdor.us",
      Password: "AAEF3D463A97EEE865B636E772C5442D7C0A",
      To: data.email,
      From: "admin@nexdor.us",
      //bcc : "admin@nexdor.us",
      Subject: "You have been registered for the Nexdor APP",
      Body: "<i>Your username and password for NexdorApp : </i> <br/> <b>Username: </b>" + data.email + "<br /> <b>Password: </b> " + data.password + "<br/>  <b>~End of Message.~</b>",
      IsHTML: true
    };

    Email.send(email).then(message => console.log('email sent status', message));

  }

}
