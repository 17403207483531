import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForminputerrorComponent } from "./forminputerror/forminputerror.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { PopmenuComponent } from "./popmenu/popmenu.component";

@NgModule({
  declarations: [ForminputerrorComponent,NotificationsComponent,PopmenuComponent],
  imports: [  
    CommonModule    
  ],
  exports: [ForminputerrorComponent,NotificationsComponent,PopmenuComponent]
})
export class ComponentsModule { }
