
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  private lookupCollection: AngularFirestoreDocument<any>;
  private rolesCollection: AngularFirestoreCollection<Role>;
  private tenanttypeCollection: AngularFirestoreCollection<any>;   
  private towerCollection: AngularFirestoreCollection<any>;    

  constructor(private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private authService: AuthService) {

      this.lookupCollection = this.db.collection('lookup').doc('nexdorlookups');
      this.rolesCollection = this.lookupCollection.collection<Role>('roles');  
      this.tenanttypeCollection = this.lookupCollection.collection<any>('tenanttype');
      this.towerCollection = this.lookupCollection.collection<any>('towers');
      
  }

  getRoles() {
    return this.rolesCollection.valueChanges({idField: 'id'});
  }

  gettenanttype() {            
    return this.tenanttypeCollection.valueChanges({idField: 'id'});
  } 
  
  getTowers() {            
    return this.towerCollection.valueChanges({idField: 'id'});
  }

}