import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/security/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/security/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/common/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./modules/security/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./modules/common/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./modules/common/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'search-filter',
    loadChildren: () => import('./modules/common/modal/search-filter/search-filter.module').then( m => m.SearchFilterPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/common/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'image',
    loadChildren: () => import('./modules/common/modal/image/image.module').then( m => m.ImagePageModule)
  },   
  {
    path: 'adduser',
    loadChildren: () => import('./modules/security/adduser/adduser.module').then( m => m.AdduserPageModule)
  }
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
