
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
    selector: 'app-forminputerror',
    templateUrl: './forminputerror.component.html',
    styleUrls: ['./forminputerror.component.scss'],
})


export class ForminputerrorComponent implements OnInit{

    @Input() form: FormGroup;
    @Input() formInput: string;
    @Input() submitAttempt: boolean = false;

    constructor() { }

  ngOnInit() {
  }

    get formInputError() {
        //Add && this.submitAttempt later if u need in below if loop
        if (this.form.controls[this.formInput] && this.form.controls[this.formInput].touched ) {
            const errors = this.form.controls[this.formInput].errors;
            for (const errorName in errors) {
                if (errors[errorName]) {
                    switch (errorName) {
                        case 'required':
                            return null;
                        case 'minlength':
                            return `Must be at least ${this.form.controls[this.formInput].errors.minlength.requiredLength} characters long.`;
                        case 'maxlength':
                            return `Must be maximum ${this.form.controls[this.formInput].errors.minlength.requiredLength} characters long.`;
                        case 'email':
                            return 'Please enter an email address';
                        case 'pattern':
                            return 'Please enter a valid data';
                        default:
                            return this.form.controls[this.formInput].errors[errorName];
                    }
                }
            }
            return null;
        }
    }


}
