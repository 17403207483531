import { Component } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Menuparams } from './shared/models/menuparams';
import { AuthService } from './shared/service/auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { LookupService } from './shared/service/lookup.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  
  public menuoptions: Array<Menuparams>;
  currentuser: AngularFirestoreDocument
  sub    
  subtowers;
  userDName: string;
  userTower: string; 
  userTowerName: string;
  towers: any;
  
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    private authService: AuthService,
    private afs: AngularFirestore,
    private lookupService: LookupService
  ) {
   
    this.getprofiledata();

    this.menudata();

    this.initializeApp();
  }

  getprofiledata(){
    this.authService.userDetails().subscribe(res => {
      
      if (res !== null) {
        //this.userEmail = res.email;

        //Get Additional user data
        this.currentuser = this.afs.doc(`users/${res.uid}`);  
        this.sub = this.currentuser.valueChanges().subscribe(event => {          
          this.userDName = event.displayname;         
          this.userTower = event.tower; 
          
          this.subtowers = this.lookupService.getTowers().subscribe(event => {
            this.userTowerName = event.filter(object => 
              { return object['tid'] === this.userTower})[0].towername ;           
          });
          
        });
    
      } 
    }, err => {
      console.log('getuserprofile', err);
    });
  }

  menudata(){
    this.menuoptions = [
      {
        title: 'Home',
        url: '/dashboard',
        direct: 'root',
        icon: 'home'
      },
      {
        title: 'Add User',
        url: '/adduser',
        direct: 'root',
        icon: 'man'
      },
      {
        title: 'About',
        url: '/about',
        direct: 'forward',
        icon: 'information-circle-outline'
      },

      {
        title: 'App Settings',
        url: '/settings',
        direct: 'forward',
        icon: 'cog'
      }
    ];
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  logout() {
    this.authService.logoutUser()
      .then(res => {
        console.log(res);
        this.navCtrl.navigateRoot('/');
      })
      .catch(error => {
        console.log(error);
      });    
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.subtowers.unsubscribe();
  }

}
